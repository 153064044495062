<template>
  <div class="input-wrapper">

    <label
      for="password"
      class="input-wrapper__label"
    >{{ $t('setPasswordData.label') }} *</label>

    <input
      id="password"
      :type="isPasswordShown ? 'text' : 'password'"
      class="input-wrapper__input"
      name="password"
      autocomplete="off"
      required
      maxlength="100"
      :placeholder="$t('setPasswordData.placeholder')"
      @input="handleInput"
    />

    <img
      class="input-wrapper__pas-toggle"
      :src="isPasswordShown ? require('../assets/images/eye-active.svg') : require('../assets/images/closed-eye-icon.svg')"
      alt="eye-icon"
      @click="togglePasswordVisibility"
    />

  </div>
</template>

<script>
import { ref, toRefs, computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'

export default {
  props: {
    modelValue: String,
    passwordRules: Object,
    passwordStrengthScore: Number,
    minPasswordStrengthScore: Number
  },
  emits: ['update:modelValue', 'password-valid', 'password-validation-status'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const { passwordRules } = toRefs(props)
    const password = ref(props.modelValue)
    const isPasswordShown = ref(false)

    const togglePasswordVisibility = () => {
      isPasswordShown.value = !isPasswordShown.value
    }

    // computed isMinLowercaseSatisfied
    const isMinLowercaseSatisfied = computed(() => {
      if (passwordRules.value && 'minLowercase' in passwordRules.value) {
        const lowercaseLetters = (password.value.match(/[a-z]/g) || [])
        return lowercaseLetters.length >= passwordRules.value.minLowercase
      }
      return true // default to true if the rule does not exist
    })

    // computed isMinUppercaseSatisfied
    const isMinUppercaseSatisfied = computed(() => {
      if (passwordRules.value && 'minUppercase' in passwordRules.value) {
        const uppercaseLetters = (password.value.match(/[A-Z]/g) || [])
        return uppercaseLetters.length >= passwordRules.value.minUppercase
      }
      return true // default to true if the rule does not exist
    })

    // computed isMinDigitSatisfied
    const isMinDigitSatisfied = computed(() => {
      if (passwordRules.value && 'minDigit' in passwordRules.value) {
        const digits = (password.value.match(/\d/g) || [])
        return digits.length >= passwordRules.value.minDigit
      }
      return true // default to true if the rule does not exist
    })

    // computed isMinSpecialSatisfied
    const isMinSpecialSatisfied = computed(() => {
      if (passwordRules.value && 'minSpecial' in passwordRules.value) {
        const specialCharacters = (password.value.match(/[^a-zA-Z0-9]/g) || [])
        return specialCharacters.length >= passwordRules.value.minSpecial
      }
      return true // default to true if the rule does not exist
    })

    // computed isPasswordValid
    const isPasswordValid = computed(() => {
      // Check if passwordRules is defined
      if (!passwordRules.value) {
        return false
      }

      // Check if minLength is satisfied
      const isMinLengthSatisfied = password.value.length >= passwordRules.value.minLength

      // Count how many of the other rules are satisfied
      const satisfiedRulesCount = [
        isMinLowercaseSatisfied.value,
        isMinUppercaseSatisfied.value,
        isMinDigitSatisfied.value,
        isMinSpecialSatisfied.value
      ].filter(Boolean).length

      // The password is valid if minLength is satisfied, at least 2 other rules are satisfied and minPasswordStrengthScore is equal to a value from server
      return isMinLengthSatisfied && satisfiedRulesCount >= 2 && props.passwordStrengthScore >= props.minPasswordStrengthScore
    })

    const handleInput = (event) => {
      password.value = event.target.value
      emit('update:modelValue', password.value)

      // Emitting validation statuses
      emit('password-validation-status', {
        minLength: password.value.length >= (passwordRules.value?.minLength || 0),
        minLowercase: isMinLowercaseSatisfied.value,
        minUppercase: isMinUppercaseSatisfied.value,
        minDigit: isMinDigitSatisfied.value,
        minSpecial: isMinSpecialSatisfied.value,
        minPasswordStrength: props.passwordStrengthScore >= props.minPasswordStrengthScore
      })
    }

    watch(isPasswordValid, (isValid) => {
      // Emit the event with the validity of the password
      emit('password-valid', isValid)
    })

    return {
      password,
      handleInput,
      isPasswordShown,
      togglePasswordVisibility,
      t
    }
  }
}
</script>

<style scoped lang="scss">
 .input-wrapper {
    position: relative;

    input::-ms-reveal, input::-ms-clear {
      display: none;
    }

    &__input {
      @include input;
      position: relative;
      &:focus {
        border: 1px solid rgb(204, 204, 204) !important;
      }

      &:focus {
          @include input-focus;
      }
    }
    &__pas-toggle {
      position: absolute;
      right: 10px;
      top: calc(50% + 10px);
      transform: translateY(-50%);
      width: 20px;
      cursor: pointer;

      @include respond-to('small') {
        top: calc(50% + 15px);
      }
    }
    &__label {
      text-align: left;

      @include input-label;
    }
  }
</style>
