import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import '@inform/design-tokens'

import { languages, defaultLocale } from './data/i18n'

import { createI18n, useI18n } from 'vue-i18n'

const messages = Object.assign(languages)
const i18n = createI18n({
  // for compositionApi : legacy has to be false
  legacy: false,
  locale: defaultLocale,
  fallbackLocale: 'de',
  warnHtmlMessage: false,
  fallbackWarn: false,
  missingWarn: false,
  messages
})

const app = createApp(App, {
  setup () {
    const { t } = useI18n()
    return { t }
  }
})

app.use(router).use(i18n).mount('#app')
