import { createRouter, createWebHistory } from 'vue-router'
import SetPassword from '@/views/SetPassword.vue'
import ForgotPassword from '@/views/ForgotPassword.vue'

const routes = [
  {
    path: '/',
    name: 'SetPassword',
    component: SetPassword,
    meta: {
      title: 'SYNCROSUPPLY CENTRAL'
    },
    beforeEnter: (to, from, next) => {
      if (to.query.route === 'forgot') {
        next({ name: 'ForgotPassword', query: to.query })
      } else {
        next()
      }
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      title: 'SYNCROSUPPLY CENTRAL'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Default Tile'
  next()
})

export default router
