<template>
    <section class="container">
      <div class="container__wrapper">

        <!-- Header -->
        <header class="container__header desktop-view">
          <div class="container__column container__column--left">
            <!-- content for left column goes here -->
            <h2 class="container__column__title ignore-design">SYNCROSUPPLY <br />CENTRAL</h2>
            <div class="container__column__logo-small">
              <img src="@/assets/images/INFORM_RGB-HZtransparent.a1efd08b.png" alt="Inform Logo" />
            </div>
          </div>
          <div class="container__column container__column--right">
            <!-- content for right column goes here -->
            <div class="container__column__logo-main">
              <img v-if="isTablet" src="@/assets/images/logo-tablet.svg" alt="Central" />
              <img v-else src="@/assets/images/Logo-Central.svg" alt="Central">
            </div>
            <div class="container__column__language-dropdown">
              <LanguageSelect @language-selected="handleLanguageSelected" />
            </div>
          </div>
        </header>

        <div
          class="container__body"
          v-if="!isSuccessResponse"
        >

          <h3 class="container__title ignore-design">{{ $t('forgotPasswordData.title') }}?</h3>

          <p class="container__par ignore-design">
            {{ $t('forgotPasswordData.par') }}
          </p>

          <section class="container__section">
            <form
              @submit.prevent="submitForm"
              class="container__form"
              autocomplete="off"
            >

              <!-- Input -->
              <EmailInput
                v-model="email"
              />

                <!-- Submit button -->
                <FormButton
                    class="btn-submit ignore-design fixed-button"
                    :label="$t('buttonsNavigation.forgotPassword')"
                    @submitForm="submitForm"
                    :disabled="!email || isSubmitting"
                />

                <!-- Cancel Link -->
                <div class="cancel-link">
                    <a href="https://central.syncro.supply/gui/#/login">
                        {{ $t('buttonsNavigation.cancel') }}
                    </a>
                </div>

            </form>

          </section>

        </div>

        <template v-if="isSuccessResponse">
          <SuccessMessage
            :showTitle="false"
            :showLink="false"
            customMessageKey="forgotPasswordData.infoMessage"
          />
        </template>

      </div>
    </section>
  </template>
<script>
import { ref, reactive, onMounted, getCurrentInstance } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { languages } from '@/data/i18n'
import SuccessMessage from '@/components/SuccessMessage.vue'
import EmailInput from '@/components/EmailInput.vue'
import LanguageSelect from '@/components/LanguageSelect.vue'
import FormButton from '@/components/UI/FormButton.vue'
import axios from 'axios'

export default {
  components: {
    LanguageSelect,
    EmailInput,
    FormButton,
    SuccessMessage
  },
  setup () {
    const { t } = useI18n({ useScope: 'global' })
    const isTablet = ref(false)
    const route = useRoute()
    const isSuccessResponse = ref(false)
    const isSubmitting = ref(false)
    const email = ref('')

    // mapping for page translation
    const mapLanguageForTranslation = (lang) => {
      const languageMapping = {
        cs: 'cz'
      }
      return languageMapping[lang] || lang
    }

    // mapping for payload
    const mapLanguageForPayload = (lang) => {
      const languageMapping = {
        cz: 'cs'
      }
      return languageMapping[lang] || lang
    }

    onMounted(() => {
      const tabletQuery = window.matchMedia('(min-width: 767px)')
      isTablet.value = tabletQuery.matches
      tabletQuery.addListener(() => {
        isTablet.value = tabletQuery.matches
      })

      let urlLanguage = route.query.language

      if (urlLanguage) {
        urlLanguage = mapLanguageForTranslation(urlLanguage.toLowerCase())

        if (Object.keys(languages).includes(urlLanguage)) {
          handleLanguageSelected(urlLanguage)
        }
      }
    })

    const instance = getCurrentInstance()
    const fallbackLocale = instance.proxy.$i18n.fallbackLocale

    const handleLanguageSelected = (selectedLanguage) => {
      setEmailData.language = selectedLanguage || fallbackLocale
      instance.proxy.$i18n.locale = setEmailData.language
    }

    const setEmailData = reactive({
      email: null,
      language: fallbackLocale
    })

    // submit form
    const submitForm = async () => {
      isSubmitting.value = true

      const payload = {
        preferredLanguage: mapLanguageForPayload(setEmailData.language).toUpperCase(),
        usernameOrEmail: email.value
      }

      try {
        await axios.get(`${process.env.VUE_APP_API_URL}/service/password/forgot`, {
          params: payload
        })

        isSuccessResponse.value = true
      } catch (error) {
        console.error('Failed to reset password:', error)
      } finally {
        isSubmitting.value = false
      }
    }

    return {
      submitForm,
      isTablet,
      handleLanguageSelected,
      isSuccessResponse,
      isSubmitting,
      setEmailData,
      email,
      t
    }
  }
}
</script>
  <style lang="scss">
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;

    &__wrapper {
      width: 100%;
      background-color: $background-color-primary;
      padding: 6px 16px 25px 16px;
      overflow: hidden;
      flex-grow: 1;

      @include respond-to('medium') {
        padding-top: calc(var(--spacing-l) * 2);
      }
    }

    &__section {
      @media only screen and (max-width: 767px) {
        .fixed-button.btn-submit {
          position: fixed;
          bottom: 15px;
          left: 50%;
          transform: translateX(-50%);
          margin-bottom: 35px;
        }
        .cancel-link {
            position: fixed;
            bottom: 15px;
            left: 50%;
            transform: translateX(-50%);
        }
      }
    }

    // Header section
    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 96px;
      height: auto;
      width: 100%;
      height: 95px;

      @include respond-to('small') {
        margin: 0 auto;
        height: auto;
        margin-bottom: 100px;
      }
    }

    &__body {
      @include respond-to('small') {
        max-width: 532px;
        margin: 0 auto;
      }
    }

    &__column {

      &--left {
        text-align: left;
        width: 60%;
        padding-top: 14px;
      }

      &--right {
        text-align: right;
        position: relative;
        max-width: 200px;
        min-width: 130px;

        @include respond-to('small') {
          max-width: 265px;
        }
      }

      // header top-left heading
      &__title {
        margin-bottom: 12px;
        @include header-title();

        @include respond-to('small') {
          margin-bottom: calc(var(--spacing-l) * 2);
        }
      }

      // header bottom-left logo
      &__logo-small {
        max-width: 78px;
        height: auto;
        position: relative;
        display: flex;

        @include respond-to('small') {
          max-width: 191px;
        }

        img {
          width: 100%;
        }
      }

      &__logo-main {
        position: relative;
        right: -17px;
        top: -7px;
        max-width: 130px;

        @include respond-to('small') {
          max-width: 300px;
        }

        img {
          width: 100%;
        }
      }

      &__language-dropdown {
        font-size: var(--fontsize-xs);
        visibility: hidden;
      }
    }

    // form center heading
    &__title {
      margin-bottom: 54px;
      @include form-title;

      @include respond-to('small') {
        margin-bottom: 100px;
      }

      @include respond-to('medium') {
        margin-bottom: calc(var(--spacing-xl) * 2 + var(--spacing-xs));
      }
    }

    &__par {
        margin-bottom: 54px;

        @include respond-to('small') {
            margin-bottom: 100px;
        }

        @include respond-to('medium') {
            margin-bottom: calc(var(--spacing-xl) * 2 + var(--spacing-xs));
        }
    }

    &__form {
      width: 100%;
      margin: auto;
      border-radius: 3px;
      margin-bottom: calc(var(--spacing-xl) + var(--spacing-s));

      @include respond-to('small') {
        margin-bottom:195px;
      }

      .input-wrapper {
        margin-bottom: 54px;

        @include respond-to('small') {
            margin-bottom: 100px;
        }

        @include respond-to('medium') {
            margin-bottom: calc(var(--spacing-xl) * 2 + var(--spacing-xs));
        }
      }
    }
    // dynamic password hint message
    &__hint {
      font-style: normal;
      font-weight: var(--fontweight-regular);
      font-size: var(--fontsize-xs);
      line-height: 14px;
      color: var(--color-error);
      text-align: left;
      margin-bottom: 21px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      img {
        margin-right: 5px;
      }
    }

    // validation wrapper
    &__validation-wrapper {
      text-align: left;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .btn-submit {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: calc(100% - 32px);
      height: 48px;
      margin-bottom: 20px;

      @include respond-to('small') {
        justify-content: center;
        max-width: 532px;
      }

      @include respond-to('medium') {
        max-width: 265px;
      }
    }

    /* Header desktop styles */
    @include respond-to('medium') {
      .container__header {
        display: none;
      }
      .desktop-view {
        max-width: 532px;
        display: flex;
        width: 100%;
        margin: 0 auto;
        flex-direction: column;
        align-items: center;
        margin-bottom: calc(var(--spacing-xl) * 2 + var(--spacing-xs));

        .container__column__logo-main {
          display: none;
        }
        .container__column--left {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
        .container__column__title {
          text-align: left;
          margin-bottom: 0;
        }
        .container__column__logo-small {
          max-width: 160px;
          display: block;
        }
        .language-select-wrapper {
          top: 70px;
          margin-right: unset;
          right: unset;
          left: 250px;
          width: 85px;
        }
      }
    }

    .is-satisfied {
      background-color: #C0DBBA;
      color: #2b7111;
    }
  }

  .w-100 {
    width: 100%;
  }

  </style>
