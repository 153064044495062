<template>
    <div class="input-wrapper">

      <label
        for="email"
        class="input-wrapper__label"
      >{{ ($t('forgotPasswordData.label')) }}:</label>

      <input
        id="email"
        class="input-wrapper__input"
        name="email"
        autocomplete="off"
        required
        maxlength="100"
        :placeholder="$t('forgotPasswordData.placeholder')"
        v-model="internalEmail"
      />

    </div>
  </template>

<script>
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

export default {
  props: {
    modelValue: {
      type: String,
      default: ''
    }
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const internalEmail = ref(props.modelValue)
    const placeholder = ref('test')

    watch(internalEmail, (newValue) => {
      emit('update:modelValue', newValue)
    })

    return {
      internalEmail,
      t,
      placeholder
    }
  }
}
</script>

  <style scoped lang="scss">
   .input-wrapper {
      position: relative;

      input::-ms-reveal, input::-ms-clear {
        display: none;
      }

      &__input {
        @include input;
        position: relative;
        &:focus {
          border: 1px solid rgb(204, 204, 204) !important;
        }

        &:focus {
            @include input-focus;
        }
      }
      &__pas-toggle {
        position: absolute;
        right: 10px;
        top: calc(50% + 10px);
        transform: translateY(-50%);
        width: 20px;
        cursor: pointer;

        @include respond-to('small') {
          top: calc(50% + 15px);
        }
      }
      &__label {
        text-align: left;

        @include input-label;
      }
    }
  </style>
