import en from './en.json'
import de from './de.json'
import cz from './cz.json'
import fr from './fr.json'
import it from './it.json'
import ro from './ro.json'

export const defaultLocale = 'de'

export const languages = {
  en,
  de,
  cz,
  fr,
  it,
  ro
}
