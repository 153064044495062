<template>
  <button
    :disabled="disabled"
    :class="{ 'disabled': disabled }"
    @click="handleClick"
    class="form-button"
    type="button"
  >
    {{ label }}
  </button>
</template>

<script>
import { toRefs } from 'vue'
export default {
  props: {
    label: {
      type: String,
      default: 'Submit'
    },
    disabled: {
      type: Boolean,
      default: true
    }
  },
  setup (props, { emit }) {
    const { disabled } = toRefs(props)

    const handleClick = () => {
      if (!disabled.value) {
        emit('submitForm')
      }
    }

    return { handleClick }
  }
}
</script>

<style lang="scss">
.form-button {
  @include form-button;
}

.disabled {
  @include form-button-disabled;
}

.btn-submit {
  @include form-button-enabled;

  &:hover:not(.disabled) {
    @include form-button-enabled-hover;
  }
}
</style>
