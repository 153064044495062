<template>
  <section class="container">
    <div class="container__wrapper">

      <!-- Header -->
      <header class="container__header desktop-view">
        <div class="container__column container__column--left">
          <!-- content for left column goes here -->
          <h2 class="container__column__title ignore-design">SYNCROSUPPLY <br />CENTRAL</h2>
          <div class="container__column__logo-small">
            <img src="@/assets/images/INFORM_RGB-HZtransparent.a1efd08b.png" alt="Inform Logo" />
          </div>
        </div>
        <div class="container__column container__column--right">
          <!-- content for right column goes here -->
          <div class="container__column__logo-main">
            <img v-if="isTablet" src="@/assets/images/logo-tablet.svg" alt="Central" />
            <img v-else src="@/assets/images/Logo-Central.svg" alt="Central">
          </div>
          <div class="container__column__language-dropdown">
            <LanguageSelect @language-selected="handleLanguageSelected" />
          </div>
        </div>
      </header>

      <div
        class="container__body"
        v-if="!isSuccessResponse && !isErrorResponse"
      >

        <h3 class="container__title ignore-design">{{ ($t('formTitle')) }}</h3>

        <section class="container__section">
          <form
            @submit.prevent="submitForm"
            class="container__form"
            autocomplete="off"
          >

            <!-- Input -->
            <SetPasswordInput
              v-if="rawPasswordRules"
              v-model="setPasswordData.password"
              :passwordRules="rawPasswordRules"
              :passwordStrengthScore="passwordStrengthScore"
              :minPasswordStrengthScore="rawPasswordRules.minPasswordStrengthScore"
              @password-valid="handlePasswordValid"
              @password-validation-status="handlePasswordValidationStatus"
            />

            <password
              v-model="setPasswordData.password"
              :strengthMeterOnly="true"
              :showStrengthMeter="false"
              @score="showScore"
            />

            <!-- Hint -->
            <p
              v-if="isPasswordValid === false"
              class="container__hint ignore-design"
            >
              <img src="../assets/images/flash.svg" />
              {{ ($t('hint.weak')) }}
            </p>

            <p
              v-else
              class="container__hint ignore-design"
              :style="{ color: activeColor }"
            >
              <img src="../assets/images/shield.svg" />
              {{ ($t('hint.strong')) }}
            </p>

            <!-- Rules -->
            <div class="container__validation-wrapper">

              <span
                class="container__rule"
                v-for="(value, key) in translatedPasswordRules"
                :key="key"
                :class="{ 'is-satisfied': validationStatuses[key] }"
              >
                <span v-if="isRuleSatisfied(key)">✓</span>
                {{ value }}
              </span>

            </div>
          </form>

          <!-- Submit button -->
          <FormButton
            class="btn-submit ignore-design fixed-button"
            :label="$t('buttonsNavigation.register')"
            @submitForm="submitForm"
            :disabled="!isPasswordValid || isSubmitting"
          />

        </section>

      </div>

      <template v-if="isSuccessResponse">
        <SuccessMessage :userData="userData" />
      </template>

      <!-- Error cpmponent -->
      <template v-if="isErrorResponse">
        <ErrorMessage :message="errorMessage" :status="errorStatus"/>
      </template>

    </div>
  </section>
</template>
<script>
import { ref, reactive, onMounted, onBeforeMount, getCurrentInstance, computed, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { translatePasswordRules } from '@/utils/passwordRulesHelper.js'
import { useI18n } from 'vue-i18n'
import { languages } from '@/data/i18n'
import SetPasswordInput from '@/components/SetPasswordInput.vue'
import ErrorMessage from '@/components/ErrorMessage.vue'
import SuccessMessage from '@/components/SuccessMessage.vue'
import Password from 'vue3-password-strength-meter'
import LanguageSelect from '@/components/LanguageSelect.vue'
import FormButton from '@/components/UI/FormButton.vue'
import axios from 'axios'

export default {
  components: {
    SetPasswordInput,
    LanguageSelect,
    FormButton,
    ErrorMessage,
    SuccessMessage,
    Password
  },
  setup () {
    const { t } = useI18n({ useScope: 'global' })
    const isTablet = ref(false)
    const isPasswordValid = ref(false)
    const activeColor = ref('#4E8042')
    const rawPasswordRules = ref(null)
    const passwordStrengthScore = ref(null)
    const route = useRoute()
    const router = useRouter()
    const isErrorResponse = ref(false)
    const isSuccessResponse = ref(false)
    const errorStatus = ref(null)
    const errorMessage = ref(null)
    const isSubmitting = ref(false)

    onMounted(async () => {
      // Wait for the router to be ready before accessing route.query.token
      await router.isReady()
    })

    const state = reactive({
      validationStatuses: {
        minLength: false,
        minLowercase: false,
        minUppercase: false,
        minDigit: false,
        minSpecial: false,
        minPasswordStrength: false
      }
    })

    const showScore = (score) => {
      passwordStrengthScore.value = score
    }

    const handlePasswordValid = (isValid) => {
      isPasswordValid.value = isValid && passwordStrengthScore.value >= 2
    }

    // Watch for events from child component
    const handlePasswordValidationStatus = (status) => {
      state.validationStatuses = { ...state.validationStatuses, ...status }
    }

    const translatedPasswordRules = computed(() => {
      if (rawPasswordRules.value) {
        return translatePasswordRules(rawPasswordRules.value, t)
      } else {
        return {}
      }
    })

    const isRuleSatisfied = (ruleKey) => {
      if (ruleKey === 'minPasswordStrength') {
        return passwordStrengthScore.value >= rawPasswordRules.value.minPasswordStrengthScore
      }
      return state.validationStatuses[ruleKey]
    }

    onBeforeMount(async () => {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/rest/service/passwordPolicy`)

        if (response.data) {
          rawPasswordRules.value = response.data
        } else {
          console.log('No data received')
        }
      } catch (error) {
        console.error('Failed to fetch password policy:', error)
      }
    })

    // mapping for page translation
    const mapLanguageForTranslation = (lang) => {
      const languageMapping = {
        cs: 'cz',
        CS: 'CZ'
      }
      console.log(lang)
      return languageMapping[lang.toLowerCase()] || lang
    }

    onMounted(() => {
      const tabletQuery = window.matchMedia('(min-width: 767px)')
      isTablet.value = tabletQuery.matches
      tabletQuery.addListener(() => {
        isTablet.value = tabletQuery.matches
      })

      let urlLanguage = route.query.language

      if (urlLanguage) {
        urlLanguage = mapLanguageForTranslation(urlLanguage.toLowerCase())

        if (Object.keys(languages).includes(urlLanguage)) {
          handleLanguageSelected(urlLanguage)
        }
      }
    })

    const instance = getCurrentInstance()
    const fallbackLocale = instance.proxy.$i18n.fallbackLocale

    const handleLanguageSelected = (selectedLanguage) => {
      setPasswordData.language = selectedLanguage || fallbackLocale
      instance.proxy.$i18n.locale = setPasswordData.language
    }

    const setPasswordData = reactive({
      password: '',
      language: fallbackLocale
    })

    // submit form
    const submitForm = async () => {
      if (!isPasswordValid.value || !isPasswordValid.value) {
        return
      }

      isSubmitting.value = true
      const tokenFromURL = route.query.token

      const payload = {
        newPassword: setPasswordData.password,
        token: tokenFromURL
      }

      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/service/password/reset`, payload)

        // Redirect to Syncro Supply
        if (response.status === 200) {
          isSuccessResponse.value = true

          setTimeout(() => {
            window.location.href = process.env.VUE_APP_API_URL
          }, 5000)
        }
      } catch (error) {
        console.error('Failed to reset password:', error)

        if (error.response) {
          isErrorResponse.value = true
          errorStatus.value = error.response.status

          if (error.response.status === 400 && error.response.data.message === 'unknown service token null') {
            errorMessage.value = t('errorPage.400.title')
          } else if (error.response.status === 404) {
            errorMessage.value = t('errorPage.404.title')
          } else if (error.response.status === 502) {
            errorMessage.value = t('errorPage.502.title')
          } else if (error.response.status === 503) {
            errorMessage.value = t('errorPage.503.title')
          } else if (error.response.status === 504) {
            errorMessage.value = t('errorPage.504.title')
          } else {
            errorMessage.value = t('errorPage.general.title')
          }
        }
      } finally {
        isSubmitting.value = false // Reset the submission state
      }
    }

    return {
      ...toRefs(state),
      setPasswordData,
      submitForm,
      isTablet,
      activeColor,
      handleLanguageSelected,
      isPasswordValid,
      translatedPasswordRules,
      rawPasswordRules,
      handlePasswordValid,
      handlePasswordValidationStatus,
      passwordStrengthScore,
      showScore,
      isRuleSatisfied,
      isErrorResponse,
      isSuccessResponse,
      errorStatus,
      errorMessage,
      isSubmitting,
      t
    }
  }
}
</script>
<style lang="scss">
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;

  &__wrapper {
    width: 100%;
    background-color: $background-color-primary;
    padding: 6px 16px 25px 16px;
    overflow: hidden;
    flex-grow: 1;

    @include respond-to('medium') {
      padding-top: calc(var(--spacing-l) * 2);
    }
  }

  &__section {
    @media only screen and (max-width: 767px) {
      .fixed-button {
        position: fixed;
        bottom: 15px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  // Header section
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 96px;
    height: auto;
    width: 100%;
    height: 95px;

    @include respond-to('small') {
      margin: 0 auto;
      height: auto;
      margin-bottom: 100px;
    }
  }

  &__body {
    @include respond-to('small') {
      max-width: 532px;
      margin: 0 auto;
    }
  }

  &__column {

    &--left {
      text-align: left;
      width: 60%;
      padding-top: 14px;
    }

    &--right {
      text-align: right;
      position: relative;
      max-width: 200px;
      min-width: 130px;

      @include respond-to('small') {
        max-width: 265px;
      }
    }

    // header top-left heading
    &__title {
      margin-bottom: 12px;
      @include header-title();

      @include respond-to('small') {
        margin-bottom: calc(var(--spacing-l) * 2);
      }
    }

    // header bottom-left logo
    &__logo-small {
      max-width: 78px;
      height: auto;
      position: relative;
      display: flex;

      @include respond-to('small') {
        max-width: 191px;
      }

      img {
        width: 100%;
      }
    }

    &__logo-main {
      position: relative;
      right: -17px;
      top: -7px;
      max-width: 130px;

      @include respond-to('small') {
        max-width: 300px;
      }

      img {
        width: 100%;
      }
    }

    &__language-dropdown {
      font-size: var(--fontsize-xs);
      visibility: hidden;
    }
  }

  // form center heading
  &__title {
    margin-bottom: 54px;
    @include form-title;

    @include respond-to('small') {
      margin-bottom: 100px;
    }

    @include respond-to('medium') {
      margin-bottom: calc(var(--spacing-xl) * 2 + var(--spacing-xs));
    }
  }

  &__form {
    width: 100%;
    margin: auto;
    border-radius: 3px;
    margin-bottom: calc(var(--spacing-xl) + var(--spacing-s));

    @include respond-to('small') {
      margin-bottom:195px;
    }

    .input-wrapper {
      margin-bottom: 5px;
    }
  }
  // dynamic password hint message
  &__hint {
    font-style: normal;
    font-weight: var(--fontweight-regular);
    font-size: var(--fontsize-xs);
    line-height: 14px;
    color: var(--color-error);
    text-align: left;
    margin-bottom: 21px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    img {
      margin-right: 5px;
    }
  }

  // validation wrapper
  &__validation-wrapper {
    text-align: left;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__rule {
    padding: 5px 7px;
    font-style: normal;
    font-weight: var(--fontweight-regular);
    font-size: var(--fontsize-s);
    line-height: 16px;
    background-color: rgb(237,238,238);
    border-radius: 3px;
    margin-right: 8px;
    margin-bottom: 8px;
    color: var(--black950);
  }

  .btn-submit {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: calc(100% - 32px);
    height: 48px;

    @include respond-to('small') {
      justify-content: center;
      max-width: 532px;
    }

    @include respond-to('medium') {
      max-width: 265px;
      margin: auto;
    }
  }

  /* Header desktop styles */
  @include respond-to('medium') {
    .container__header {
      display: none;
    }
    .desktop-view {
      max-width: 532px;
      display: flex;
      width: 100%;
      margin: 0 auto;
      flex-direction: column;
      align-items: center;
      margin-bottom: calc(var(--spacing-xl) * 2 + var(--spacing-xs));

      .container__column__logo-main {
        display: none;
      }
      .container__column--left {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      .container__column__title {
        text-align: left;
        margin-bottom: 0;
      }
      .container__column__logo-small {
        max-width: 160px;
        display: block;
      }
      .language-select-wrapper {
        top: 70px;
        margin-right: unset;
        right: unset;
        left: 250px;
        width: 85px;
      }
    }
  }

  .is-satisfied {
    background-color: #C0DBBA;
    color: #2b7111;
  }
}

.w-100 {
  width: 100%;
}

</style>
