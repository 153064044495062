<template>
    <div class="language-select-wrapper">
        <img
            src="@/assets/images/earth-logo.svg"
            alt="language selector logo"
            class="language-select-wrapper__logo"
        >
        <select
            v-model="$i18n.locale"
            class="language-select-wrapper__select"
            @change="emitLanguageSelected"
        >
            <option
                v-for="(lang) in displayLangs"
                :key="lang.title"
                :value="lang.language"
                class="language-select-wrapper__option"
            >
                {{ lang.displayLanguage.toUpperCase() }}
            </option>
        </select>
    </div>
</template>

<script>
import { ref, getCurrentInstance, computed } from 'vue'
export default {
  setup () {
    // refs data
    const instance = getCurrentInstance()
    const langs = ref([
      { language: 'en', title: 'English' },
      { language: 'de', title: 'German' },
      { language: 'cz', title: 'Czech' },
      { language: 'fr', title: 'French' },
      { language: 'it', title: 'Italian' },
      { language: 'ro', title: 'Romanian' }
    ])

    const displayLangs = computed(() => {
      return langs.value.map(lang => ({
        ...lang,
        displayLanguage: lang.language === 'cs' ? 'cz' : lang.language
      }))
    })

    const emitLanguageSelected = () => {
      instance.emit('language-selected', instance.proxy.$i18n.locale)
    }

    return { langs, displayLangs, emitLanguageSelected }
  }
}
</script>

<style lang="scss" scoped>
    .language-select-wrapper {
        position: absolute;
        top: 75px;
        right: -5px;
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;

        @include respond-to('small') {
            top: 125px;
            width: 95px;
            margin-right: 25px;
        }

        &__select {
            width: inherit;
            text-align: center;
            color: var(--black950);
            border: none;
            background-color: var(--white0);

            // Include the base font properties
            @include font-properties-base(var(--fontsize-s), var(--fontweight-regular), normal, 16);

            // Include the tablet font properties
            @include font-properties-tablet(var(--fontsize-m), var(--fontweight-regular), normal, 19);

            @include respond-to('small') {
                padding-top: 1px;
            }

            &:focus {
                outline: 0;
            }

            option {
                margin: 0;
                padding: var(--spacing-s) 0;
                text-indent: 15px;
            }
       }
    }
</style>
