export const translatePasswordRules = (rules, t) => {
  const translatedRules = {}

  for (const [key, value] of Object.entries(rules)) {
    switch (key) {
      case 'minLength':
        translatedRules[key] = t('passwordRules.minLength', { length: value })
        break
      case 'minLowercase':
        translatedRules[key] = t('passwordRules.minLowercase', { count: value })
        break
      case 'minUppercase':
        translatedRules[key] = t('passwordRules.minUppercase', { count: value })
        break
      case 'minDigit':
        translatedRules[key] = t('passwordRules.minDigit', { count: value })
        break
      case 'minSpecial':
        translatedRules[key] = t('passwordRules.minSpecial', { count: value })
        break
      default:
        break
    }
  }

  return translatedRules
}
