<template>
    <div class="success-page-wrapper">
      <div class="success-page-wrapper__img-holder">

        <svg
            class="checkmark"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52">
            <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
        </svg>

      </div>

      <!-- Title -->
      <h3
        v-if="showTitle"
        class="success-page-wrapper__title ignore-design"
      >
        {{ $t('successPage.title') }}
      </h3>

      <!-- Paragraph -->
      <p class="success-page-wrapper__par ignore-design">
        {{ customMessageKey ? $t(customMessageKey) : $t('successPage.notice') }}
        <b v-if="showLink">
            <a :href="apiUrl" class="success-page-wrapper__link">{{ ($t('successPage.link')) }}.</a>
        </b>
      </p>

    </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

export default {
  props: {
    showTitle: {
      type: Boolean,
      default: true
    },
    showLink: {
      type: Boolean,
      default: true
    },
    customMessageKey: {
      type: String,
      default: null
    }
  },

  setup () {
    const { t } = useI18n()
    const apiUrl = computed(() => process.env.VUE_APP_API_URL)

    return {
      apiUrl,
      t
    }
  }
}
</script>

<style lang="scss" scoped>
    .success-page-wrapper {
      margin-top: calc(var(--spacing-xl) * 3 + var(--spacing-s));
      margin-bottom: calc(var(--spacing-xl) * 3 + var(--spacing-s));
      margin-left: 0;
      margin-right: 0;

      @include respond-to('medium') {
        padding-top: calc(var(--spacing-xl) * 2 + var(--spacing-xs));
      }

      &__img-holder {
        max-width: 120px;
        margin: auto;
        margin-bottom: calc(var(--spacing-s) + var(--spacing-xl));

        @include respond-to('small') {
          max-width: 176px;
          margin-bottom: calc(var(--spacing-xl) * 3);
        }

        .checkmark {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            stroke-width: 2;
            stroke: $color-primary;
            stroke-miterlimit: 10;
            box-shadow: inset 0px 0px 0px $color-primary;
            animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
        }
        .checkmark__circle {
            stroke-dasharray: 166;
            stroke-dashoffset: 166;
            stroke-width: 2;
            stroke-miterlimit: 10;
            stroke: $color-primary;
            fill: $color-white;
            animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
        }

        .checkmark__check {
            transform-origin: 50% 50%;
            stroke-dasharray: 48;
            stroke-dashoffset: 48;
            animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
        }

        @keyframes stroke {
            100% {
                stroke-dashoffset: 0;
            }
        }

        @keyframes scale {
            0%, 100% {
                transform: none;
            }

            50% {
                transform: scale3d(1.1, 1.1, 1);
            }
        }

        @keyframes fill {
            100% {
                box-shadow: inset 0px 0px 0px 30px $color-primary;
            }
        }

      }
      &__title {
        @include form-title;
        margin-bottom: var(--spacing-l);

        @include respond-to('small') {
          margin-bottom: calc(var(--spacing-xl) * 2);
        }
      }
      &__par {
        // Include the base font properties
        @include font-properties-base(var(--fontsize-s), var(--fontweight-regular), normal);

        // Include the tablet font properties
        @include font-properties-tablet(var(--fontsize-m), var(--fontweight-regular), normal);

        @include respond-to('medium') {
            max-width: 75%;
            margin: auto;
        }

        @include respond-to('large') {
            max-width: 532px;
            margin: auto;
        }
      }
      &__link {
        margin-top: var(--spacing-l);
        text-decoration: underline;
      }
    }
</style>
