<template>
    <div class="error-page-wrapper">

      <div class="error-page-wrapper__img-holder">
        <img src="../assets/images/error-truck-icon.svg" class="error-page-wrapper__img" alt="error icon">
      </div>

      <h3 class="error-page-wrapper__title">
        <span>Status {{ status }}: </span>
        {{ message }}
      </h3>

    </div>
</template>

<script>
export default {
  props: {
    status: {
      type: Number,
      default: null
    },
    message: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
  .error-page-wrapper {
    margin: 6rem 0 9rem;

    &__img-holder {
      max-width: 300px;
      margin: auto;
      margin-bottom: 38px;

      @include respond-to('small') {
        max-width: 400px;
        margin-bottom: calc(var(--spacing-xl) * 3);
      }
    }

    &__img {
      width: 100%;
    }

    &__title {
      @include form-title;
      margin: 0;
      margin-bottom: var(--spacing-l);

      @include respond-to('small') {
        margin-bottom: calc(var(--spacing-xl) * 2);
      }
    }

  }
</style>
